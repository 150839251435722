const config = {
  dev: {
    // api:'http://192.168.0.107:8084/admin/api'
    api:"https://api.alphant.ai/admin/api",
  },
  test: {
    api: "https://test-api.alphant.ai/admin/api",
  },
  pro: {
    api: "https://api.alphant.ai/admin/api",
  },
};

export default {
  ENV: process.env.VUE_APP_DOMAIN_CONFIG,
  ...config[process.env.VUE_APP_DOMAIN_CONFIG],
};
