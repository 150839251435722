import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/common.css";
import * as Fetch from "@/service/fetch";
import selfFunc from "@/utils/common";
import * as filters from '@/utils/filter';
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import {Base64} from "js-base64";
import VueClipboard from 'vue-clipboard2'

Vue.use(ElementUI);
Vue.use(VueClipboard);

Vue.prototype.$http = Fetch;
Vue.prototype.$Base64 = Base64;
Vue.prototype.$selfFunc = selfFunc;

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
