import Vue from "vue";
import Vuex from "vuex";
import config from "@/config/env";
Vue.use(Vuex);
const state = {
  userInfo:{}
};
const mutations = {
  updateuserInfoChanged(state, value) {
    state.userInfo = value;
  },
};
const actions = {
  
};
export default new Vuex.Store({
  state,
  mutations,
  actions,
});
