import R from '@/utils/request'

export const login = (obj) => R.post("/auth/login", obj);
export const getUserList = (param) => R({ url: '/user/list-all', method: 'get', param })
// export const getUserInfo = (param) => R({ url: '/user/list/info', method: 'get', param })
export const getUserInfo = (param) => R({ url: '/user/info', method: 'get', param })
export const getOrders = (param) => R({ url: '/user/asset-list', method: 'get', param })
export const antFormList = (param) => R({ url: '/ant-form/list', method: 'get', param })
export const topicList = (param) => R({ url: '/article/topic-list', method: 'get', param })
export const topicCreate = (param) => R({ url: '/article/topic-save', method: 'post', param })
export const topicDelete = (param) => R({ url: '/topic/delete', method: 'post', param })
export const bannerList = (param) => R({ url: '/biz/banner-list', method: 'get', param })
export const bizSave = (param) => R({ url: '/biz/save', method: 'post', param })
export const bizdDelete = (param) => R({ url: '/biz/delete', method: 'post', param })
export const articleList = (param) => R({ url: '/article/content-list', method: 'get', param })
export const articleDelete = (param) => R({ url: '/article/remove', method: 'post', param })
export const articleCreate = (param) => R({ url: '/article/save-content', method: 'post', param })
export const giftList = (param) => R({ url: '/gift/list-all', method: 'get', param })
export const giftDelete = (param) => R({ url: '/gift/remove', method: 'post', param })
export const giftSave = (param) => R({ url: '/gift/save-gift', method: 'post', param })
export const taskList = (param) => R({ url: '/task/task-list', method: 'get', param })
export const taskSave = (param) => R({ url: '/task/save-task', method: 'post', param })
export const taskPublish = (param) => R({ url: '/task/task-publish', method: 'post', param })
export const taskBranchSave = (param) => R({ url: '/task/save-branch', method: 'post', param })
export const taskUpDown = (param) => R({ url: '/task/update-setp', method: 'post', param })
export const taskInfo = (param) => R({ url: '/task/task-info', method: 'get', param })
export const taskDelete = (param) => R({ url: '/task/delete', method: 'post', param })
export const taskDeleteBranch = (param) => R({ url: '/task/delete-branch', method: 'post', param })
export const taskTagList = (param) => R({ url: '/task/tag-list', method: 'get', param })
export const taskTagSave = (param) => R({ url: '/task/save-tag', method: 'post', param })
export const taskTagDelete = (param) => R({ url: '/task/remove-tag', method: 'post', param })
export const roomList = (param) => R({ url: '/room/list-all', method: 'get', param })
export const ipfsList = (param) => R({ url: '/nft/list-nft', method: 'get', param })
export const ipfsUploadJson = (param) => R({ url: '/nft/upload/nft-json', method: 'post', param })
export const ipfsSave = (param) => R({ url: '/nft/save-nft', method: 'post', param })
export const propsList = (param) => R({ url: '/nft/list-props', method: 'get', param })
export const propsSave = (param) => R({ url: '/nft/save-props', method: 'post', param })
export const ipfsRefresh = (param) => R({ url: '/ipfs/refresh', method: 'post', param })
export const whiteAddressList = (param) => R({ url: '/white/address/list', method: 'get', param })
export const whiteAddressLocal = (param) => R({ url: '/white/address/config/local', method: 'get', param })
export const whiteAddressSave = (param) => R({ url: '/white/address/save', method: 'post', param })
export const whiteAddressConfigLocal = (param) => R({ url: '/white/address/config/local', method: 'post', param })
export const whiteAddressOpen = (param) => R({ url: '/white/address/open', method: 'post', param })
export const whiteAddressClose = (param) => R({ url: '/white/address/close', method: 'post', param })
export const whiteAddressSync = (param) => R({ url: '/white/address/sync', method: 'post', param })



export const sysUserList = (param) => R({ url: '/sys-user/list', method: 'get', param })
export const sysUserAdd = (param) => R({ url: '/sys-user/add', method: 'post', param })
export const sysUserUpdate = (param) => R({ url: '/sys-user/update', method: 'post', param })
export const sysUserPassword = (param) => R({ url: '/sys-user/password', method: 'post', param })
export const sysUserDelete = (param) => R({ url: '/sys-user/delete', method: 'post', param })

export const roleList = (param) => R({ url: '/role/list', method: 'get', param })
export const roleAdd = (param) => R({ url: '/role/add-role', method: 'post', param })
export const roleUpdate = (param) => R({ url: '/role/update-role', method: 'post', param })
export const roleDelete = (param) => R({ url: '/role/delete-role', method: 'post', param })
export const accountAddrole = (param) => R({ url: '/role/save-user-role', method: 'post', param })


export const menuListAll = (param) => R({ url: '/menu/list-all', method: 'get', param })
export const menuList = (param) => R({ url: '/menu/list', method: 'get', param })
export const roleMenuList = (param) => R({ url: '/menu/list-by-role', method: 'get', param })
export const menuAdd = (param) => R({ url: '/menu/add', method: 'post', param })
export const menuUpdate = (param) => R({ url: '/menu/update', method: 'post', param })
export const menuBatchUpdate = (param) => R({ url: '/menu/batch-update', method: 'post', param })
export const menuDelete = (param) => R({ url: '/menu/delete', method: 'post', param })
export const saveRoleMenu = (param) => R({ url: '/menu/save-role-menu', method: 'post', param })
export const saveMenuResource = (param) => R({ url: '/menu/save-menu-resource', method: 'post', param })



export const resourcesList = (param) => R({ url: '/resources/list', method: 'get', param })
export const resourcesAdd = (param) => R({ url: '/resources/add-resource', method: 'post', param })
export const resourcesUpdate = (param) => R({ url: '/resources/update', method: 'post', param })
export const resourcesDelete = (param) => R({ url: '/resources/delete', method: 'post', param })
export const resourcesSaveRole = (param) => R({ url: '/resources/save-role-resource', method: 'post', param })









