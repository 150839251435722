import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login"),
  },
  {
    path: "/",
    component: () => import("@/layar"),
    redirect: "/infoManagement/topicList",
    children: [
      {
        path: "/userManagement/userList",
        name: "userList",
        component: () => import("@/views/userManagement/userList"),
      },
      {
        path: "/userManagement/userList/userInfo",
        name: "userInfo",
        component: () => import("@/views/userManagement/userListInfo"),
      },
      {
        path: "/userManagement/recruitJoin",
        name: "recruitJoin",
        component: () => import("@/views/userManagement/recruitJoin"),
      },
      // 话题管理
      {
        path: "/infoManagement/topicList",
        name: "topicList",
        component: () => import("@/views/infoManagement/topicList"),
      },
      // banner管理
      {
        path: "/infoManagement/bannerList",
        name: "bannerList",
        component: () => import("@/views/infoManagement/bannerList"),
      },
      //信息列表
      {
        path: "/infoManagement/articleList",
        name: "articleList",
        component: () => import("@/views/infoManagement/articleList"),
      },
      //信息发布
      {
        path: "/infoManagement/articleList/articlePublish",
        name: "articlePublish",
        component: () => import("@/views/infoManagement/articlePublish"),
      },
      // Ipfs管理
      {
        path: "/infoManagement/nftList",
        name: "nftList",
        component: () => import("@/views/infoManagement/nftList"),
      },
      // 白名单管理
      {
        path: "/infoManagement/whiteList",
        name: "whiteList",
        component: () => import("@/views/infoManagement/whiteList"),
      },
      // 白名单管理
      {
        path: "/infoManagement/itemList",
        name: "itemList",
        component: () => import("@/views/infoManagement/itemList"),
      },
      // 房间管理
      {
        path: "/roomManagement/roomList",
        name: "roomList",
        component: () => import("@/views/roomManagement/roomList"),
      },
      // 礼物管理
      {
        path: "/giftManagement/giftList",
        name: "giftList",
        component: () => import("@/views/giftManagement/giftList"),
      },
      // 任务管理
      {
        path: "/taskManagement/taskList",
        name: "taskList",
        component: () => import("@/views/taskManagement/taskList/taskList"),
      },
      // 任务详情
      {
        path: "/taskManagement/taskList/taskInfo",
        name: "taskListInfo",
        component: () => import("@/views/taskManagement/taskList/taskListInfo"),
      },
      // 任务标签
      {
        path: "/taskManagement/taskLabels",
        name: "taskLabels",
        component: () => import("@/views/taskManagement/taskLabels"),
      },


      {
        path: "/systemManagement/roleList",
        name: "roleList",
        component: () => import("@/views/systemManagement/roleList"),
      },
      {
        path: "/systemManagement/permissionAssign",
        name: "permissionAssign",
        component: () => import("@/views/systemManagement/permissionAssign"),
      },
      {
        path: "/systemManagement/accountList",
        name: "accountList",
        component: () => import("@/views/systemManagement/accountList"),
      },
      {
        path: "/systemManagement/apiList",
        name: "apiList",
        component: () => import("@/views/systemManagement/apiList"),
      }
    ],
  },
];

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    window.scrollTo(0, 0);
    return { x: 0, y: 0 };
  },
  mode: "hash",
  base: "/admin",
  routes,
});

router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem("token");
  if (to.name == "login" || token) {
    next();
  } else {
    next({ name: "login" });
  }
});

export default router;
